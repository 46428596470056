import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { colorLabelQueryKeys } from '@/src/modules/labels/queries/colorLabelQueryKeys';
import { useWoody } from '@/src/services/woody/woody';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export const useQueryFolderLabels = (folderId?: string | null) => {
  const { client } = useWoody();
  return useQuery({
    queryKey: colorLabelQueryKeys.folder(folderId),
    queryFn: async () => {
      const res = await client.v2({
        endpoint: '/v2/folders/{folderId}/color-labels',
        params: {
          folderId: folderId!,
        },
      });
      // incorrect typing in the API schema
      return res.data.labels as unknown as ApiColorLabel[];
    },
    enabled: !!folderId,
  });
};

export const useQueryFolderLabelsHelpers = () => {
  const queryClient = useQueryClient();

  const invalidate = (folderId: string) => {
    queryClient.invalidateQueries({
      queryKey: colorLabelQueryKeys.folder(folderId),
    });
  };

  const updateCachedFolderLabel = (
    folderId: string,
    labelId: ApiColorLabel['id'],
    name: ApiColorLabel['name'],
  ) => {
    queryClient.setQueryData<ApiColorLabel[]>(colorLabelQueryKeys.folder(folderId), (data) => {
      if (!data) {
        return data;
      }
      return data.map((label) => {
        if (label.id === labelId) {
          return {
            ...label,
            name,
          };
        }
        return label;
      });
    });
  };

  return {
    invalidate,
    updateCachedFolderLabel,
  };
};
